import cookieFetch from '../packs/cookie'

export default class SectionConfig {

  static initTimeout(tag) {
    this.totalTime = 60 * 20
    this.expire_session = new Date().getTime() + (1000 * this.totalTime)
    this.sessionInterval = setInterval(() => {

      // Setar a tag com o tempo restante
      tag && this.updateTag(tag)

      if (new Date().getTime() + 1000 > this.expire_session) {
        this.clearTimeout()
        swal({
          title: "Sessão expirada!",
          text: "Sua sessão expirou por questões de segurança.\nClick em OK para renovar sua sessão.",
          icon: "warning",
          dangerMode: true,
        }).then((ev) => {
          location.reload()
        })
      }
    }, 1000)
  }

  static clearTimeout() {
    console.log("Interval:", this.sessionInterval)
    this.sessionInterval && clearInterval(this.sessionInterval)
  }

  static updateTag(tag) {
    document.getElementById(tag).innerHTML = new Date(this.expire_session - new Date().getTime()).toISOString().substr(14, 5)
    // window.cectl.labelTimeLeftTarget.style.display = "block"
  }

}