import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import consumer from "../channels/consumer"


export default class extends Controller {
  // static targets = [ "output" ]

  connect() {
    const payloads = this.data.get("ids") ? JSON.parse(this.data.get("ids")) : []
    console.log("Payloads", payloads)
    if(payloads) {
      this.payload_consumer = payloads.map(payload_id => {
        return consumer.subscriptions.create({channel: "PayloadChannel", id: payload_id}, {
          connected() {
            console.log("Payload in controller connected")
          },
          
          disconnected() {
            console.log("[disconnected] Payload in controller")
            // Called when the subscription has been terminated by the server
          },
        
          received(data) {
            if(data.status == 'working') {
              document.getElementById(`progress-bar-${data.id}`).style.width = `${data.progress}%`
              document.getElementById(`payload-success-${data.id}`).innerHTML = data.success_count
              document.getElementById(`payload-errors-${data.id}`).innerHTML = data.errors_count
            } else if(data.status == 'queue') {
              document.getElementById(`payload-status-${data.id}`).innerHTML = `<span class="badge badge-info">Processando</span>`
            } else if(data.status == 'done') {
              document.getElementById(`payload-status-${data.id}`).innerHTML = `<span class="badge badge-success">Concluido</span>`
              document.getElementById(`payload-progress-${data.id}`).innerHTML = `<span class="badge badge-pill badge-info">Concluído</span>`
              document.getElementById(`payload-report-${data.id}`).innerHTML = `<a href="/payloads/report?uuid=${data.uuid}">Download</a>`
              // 
            }
            // console.log(data)
          }
        })
        
      })
    }
  }

  disconnect() {
    if(this.payload_consumer){
      this.payload_consumer.forEach(payload => {
        payload.unsubscribe()
      });
    }
  }
}


