// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This custom generate is created by @warleyolf
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="card_tokens">
//   <h1 data-target="card_tokens.output"></h1>
// </div>

import { Controller } from "stimulus";
import swal from 'sweetalert';
import Rails from '@rails/ujs';


export default class extends Controller {
  // static targets = [ "output" ]

  connect() {
    
  }
}
