// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This custom generate is created by @warleyolf
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="payment_events">
//   <h1 data-target="payment_events.output"></h1>
// </div>

import { Controller } from "stimulus";
import swal from 'sweetalert';
import Rails from '@rails/ujs';

import 'datatables.net'
import 'datatables.net-bs4'
import jsZip from 'jszip';
window.JSZip = jsZip;

import '../packs/dataTables.buttons'
// import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.js'

import 'datatables.net-buttons/js/buttons.colVis.js'



export default class extends Controller {
  // static targets = [ "output" ]
  
  connect() {
	  if(this.data.has("actionForm")){
		  this.load_form(this.data.get("actionForm"))
		}
		$.extend($.fn.dataTableExt.oSort, {
			"date-uk-pre": function (a) {
				var ukDatea = a.split('/');
				console.log(ukDatea)
				return (ukDatea[2] + ukDatea[1] + ukDatea[0]) * 1;
			},
	
			"date-uk-asc": function (a, b) {
				return ((a < b) ? -1 : ((a > b) ? 1 : 0));
			},
	
			"date-uk-desc": function (a, b) {
				return ((a < b) ? 1 : ((a > b) ? -1 : 0));
			}
		});
	
		var buttonCommon = {
			exportOptions: {
				format: {
					body: function (data, row, column, node) {
						return node.dataset.originalData || data;
					}
				}
			}
		};
	const dataTable = $('#payment-events-datatable').DataTable({
		searchable: true,
		fixedHeight: true,
		language: {
				paginate: {
					previous: "Anterior",
					next: "Proximo"
				},
				lengthMenu: "Itens por página: _MENU_",
				processing: `<div class="spinner-border text-success" role="status"></div><p class="text-secondary">Processando...</p>`,
				search: "Pesquisar",
				zeroRecords: "Sem registros",
				info: "Mostrando _START_ até _END_ de _TOTAL_ registros"
		},
		labels: {
			placeholder: "Pesquisar...", // The search input placeholder
			perPage: "{select} itens por pagina", // per-page dropdown label
			noRows: "Nenhuma entrada encontrada", // Message shown when there are no search results
			info: "Exibindo {start} até {end} de {rows} itens" //
		},
		order: [[ 6, "desc" ]],
		dom: 'Bfrtip',
			buttons: [
				$.extend(true, {}, buttonCommon, {
					extend: 'excelHtml5',
					className: "btn btn-sm btn-outline-success",
					text: "Exportar Excel",
					exportOptions: {
						orthogonal: 'export'
					}
				}),
		]
	})

  }

  load_form(action) {
  	// on load form
  }

  delete(event) {
  	const id = event.target.dataset.value;
  	swal({
		title: "Você tem certeza?",
		text: "Não será possível recuperar esses dados!",
		icon: "warning",
		showCancelButton: true,
		buttons: true,
		// confirmButtonColor: "#DD6B55",
		confirmButtonText: "Sim, quero remover!",
		dangerMode: true
	}).then((willDelete) => {
		if(willDelete){
			fetch(`/payment_events/${id}`, {
				method: "DELETE",
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': Rails.csrfToken()
				},
				body: JSON.stringify({
					"_method": "delete"
				})
			}).then( (response) => {
				console.log(response);
				if(response.ok){
					swal("Excluido!", "Os dados foram eliminados.", "success");
				} else {
					swal("Erro!", "Os dados não foram excluidos.", "error");
				}
			});
		}
	});
  } // Delete
}
