import { Controller } from "stimulus"
import Calendar from "tui-calendar"
import "tui-calendar/dist/tui-calendar.css";
import '../packs/bootstrap-notify.js'

export default class extends Controller {

  calendar = null;
  connect() {
    if (document.getElementById('auto-open-what-news')){
      $('#whats-new-modal').modal('show');
    }
    if (this.data.has("actionForm")) {
      switch (this.data.get("actionForm")) {
        case "calendar": {
          this.onCalendar()
          console.log("Calendar load!")
          break
        }
        case "edit": {
          this.on_edit()
          break
        }
      }
    }
  }


  onCalendar() {
    this.calendar = new Calendar('#calendar', {
      defaultView: 'month',
      taskView: true,
      useCreationPopup: true,
      template: {
        monthDayname: function (dayname) {
            return '<span class="calendar-week-dayname-name">' + dayname.label + '</span>';
          }
      }
    });
    this.loadSchedules()

  }

  loadSchedules() {
    this.calendar.createSchedules([{
            id: '1',
            calendarId: '1',
            title: 'Reunião de alinhamento diário',
            category: 'time',
            dueDateClass: '',
            start: '2020-05-20T17:30:00-03:00',
            end: '2020-05-20T18:00:00-03:00'
          }, {
            id: '2',
            calendarId: '1',
            title: 'Reunião de alinhamento diário',
            category: 'time',
            dueDateClass: '',
            start: '2020-05-21T17:30:00-03:00',
            end: '2020-05-21T18:00:00-03:00'
          }, {
            id: '4',
            calendarId: '1',
            title: 'Reunião de alinhamento diário',
            category: 'time',
            dueDateClass: '',
            start: '2020-05-22T17:30:00-03:00',
            end: '2020-05-22T19:00:00-03:00'
          }
    ]);
  }


  
  disconnect() {
    console.log("unload Dashboard");
  }
}