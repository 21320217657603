// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This custom generate is created by @warleyolf
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="clients">
//   <h1 data-target="clients.output"></h1>
// </div>

import { Controller } from "stimulus"
import swal from 'sweetalert'
import Rails from '@rails/ujs'
import Inputmask from 'inputmask'
import "bootstrap-datepicker"
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js"
// import Tagify from '@yaireo/tagify/dist/tagify.min.js'
// import '@yaireo/tagify/dist/tagify.css'

// import { DataTable } from "simple-datatables"


import 'datatables.net/js/jquery.dataTables.js'
import 'datatables.net-bs4/js/dataTables.bootstrap4.js'

export default class extends Controller {
  // static targets = [ "output" ]

  connect() {
    if(this.data.has("actionForm")){
      this.load_form()
    }
    // const dataTable = new DataTable("#clients-table", {
      var coluns = []
      $(".dataTable").each(function (i, e) {
        var t = $(e)
        coluns = t.children('thead').children('tr').children().map(function (_i, _e) {
          return {
            data: $(_e).data('column')
          }
        })
      })

    if (!$.fn.dataTable.isDataTable('#clients-table')) {
      $('#clients-table').DataTable({
        // searchable: true,
        fixedHeight: true,
        processing: true,
        serverSide: true,
        "searchDelay": 350,
        ajax: {
          url: $('#clients-table').data('source')
        },
        // "columnDefs": [{
        //     "targets": [1,2],
        //     "visible": false
        //   }
        // ],
        columns: coluns,
        language: {
          paginate: {
            previous: "Anterior",
            next: "Proximo"
          },
          lengthMenu: "Itens por página: _MENU_",
          processing: `<div class="spinner-border text-success" role="status"></div><p class="text-secondary">Processando...</p>`,
          search: "Pesquisar",
          zeroRecords: "Sem registros",
          info: "Mostrando _START_ até _END_ de _TOTAL_ registros"
        },
        labels: {
          placeholder: "Pesquisar...", // The search input placeholder
          perPage: "{select} itens por pagina", // per-page dropdown label
          noRows: "Nenhuma entrada encontrada", // Message shown when there are no search results
          info: "Exibindo {start} até {end} de {rows} itens" //
        },
      })
    }
  }

  load_form() {
    new Inputmask("(99) 99999-9999").mask(document.getElementById("client_phone"));
    new Inputmask("(99) 9999-9999").mask(document.getElementById("client_telephone"));
    new Inputmask({ mask: ["999.999.999-99", "99.999.999/9999-99"]}).mask(document.getElementById("client_document"));
    new Inputmask("99999-999").mask(document.getElementById("client_cep"));
    
    // // var input_email = document.querySelector('input[name=basic]');
    // var input_email = document.querySelector('#client_email');
    // new Tagify(input_email, {
    //   originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    // })


    $('[data-provider="datepicker"]').datepicker({startView: 3, language: "pt-BR"});

  }

  findCep(event) {
    if (event.currentTarget.value.length > 8){
      var find_cep_notify = $.notify({ icon: "nc-icon nc-bell-55", message: "Pesquisando o <b>CEP</b>, aguarde!" }, { type: 'info', timer: 4000, placement: { from: 'top', align: 'right' } });
      fetch(`https://viacep.com.br/ws/${event.currentTarget.value}/json/`).then((response) => {
        find_cep_notify.close();
        if (response.ok) {
          return response.json().then(function (json) {
            console.log("Retorno: ", json)
            $.notify({ icon: "nc-icon nc-bell-55", message: "Busca Finalizada!" }, { type: 'success', timer: 2000, placement: { from: 'top', align: 'right' } });
            document.getElementById("client_address").value = json.logradouro
            document.getElementById("client_neighborhood").value = json.bairro
            document.getElementById("client_city").value = json.localidade
            document.getElementById("client_state").value = json.uf
          });
          } else {
            console.log('Network response was not ok.');
            $.notify({ icon: "nc-icon nc-bell-55", message: "Erro ao buscar o <b>CEP</b>. Verifique a conexão!" }, { type: 'danger', timer: 20000, placement: { from: 'top', align: 'right' } });
          }
        })
        .catch((error) => {
          find_cep_notify.close();
          $.notify({ icon: "nc-icon nc-bell-55", message: "Erro ao buscar o <b>CEP</b>. Verifique a conexão!" }, { type: 'danger', timer: 20000, placement: { from: 'top', align: 'right' } });
      });
    }
  }

  delete(event) {
    const id = event.target.parentElement.parentElement.dataset.clientsId || event.target.parentElement.dataset.clientsId || event.target.dataset.clientsId;
    swal({
    title: "Você tem certeza?",
    text: "Não será possível recuperar esses dados!",
    icon: "warning",
    showCancelButton: true,
    buttons: true,
    // confirmButtonColor: "#DD6B55",
    confirmButtonText: "Sim, quero remover!",
    dangerMode: true
  }).then((willDelete) => {
    if(willDelete){
      fetch(`/clients/${id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify({
          "_method": "delete"
        })
      }).then( (response) => {
        console.log(response);
        if(response.ok){
          swal("Excluido!", "Os dados foram eliminados.", "success").then((e) => {
            location.reload()
          });
        } else {
          swal("Erro!", "Os dados não foram excluidos.", "error");
        }
      });
    }
  });
  } // Delete
}
