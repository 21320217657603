// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This custom generate is created by @warleyolf
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="extern_registers">
//   <h1 data-target="extern_registers.output"></h1>
// </div>

import { Controller } from "stimulus"
// import swal from 'sweetalert'
import Swal2 from 'sweetalert2'
import Rails from '@rails/ujs'
import Inputmask from 'inputmask'
import "bootstrap-datepicker"
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js"
import "jquery-validation"
import "twitter-bootstrap-wizard"
import cookieFetch from '../packs/cookie'
import Moment from 'moment'
// import "twitter-bootstrap-wizard.css"

import sectionConfig from "../packs/sectionConfig"


export default class extends Controller {
  static targets = ["paymentType", "sectionNewCard", "sectionDebitAccount", "sectionBankSlip", "submit",
  "document", "birthday", "enabled3ds", "card", "sectionNewCardFields", "ccv2Content", "sectionTokenCards"]

  connect() {
    if (this.data.has("actionForm")) {
      switch (this.data.get("actionForm")) {
        case "index": {
          this.onIndex()
          break
        }
        case "first_page": {
          this.onFirstPage()
          break
        }
        case "challenge": {
          this.onChallenge()
          break
        }
        case "authenticated": {
          this.onAuthenticated()
          break
        }
      }
    }
  }

  disconnect() {
    sectionConfig.clearTimeout()
  }

  async onAuthenticated(e) {
    
    sectionConfig.initTimeout()
    new Inputmask("999[9]").mask(document.getElementById("ccv"))
    new Inputmask("999[9]").mask(document.getElementById("ccv2"))
    new Inputmask("9999 9999 9999 999[9] [9][9][9]").mask(document.getElementById("card_number"))
    new Inputmask({
      regex: "^(1[0-2]|0[1-9]|\d)\/([0-9][0-9])$"
    }).mask(document.getElementById("expiration_date"))
    new Inputmask({
      alias: 'currency',
      prefix: 'R$ ',
      radixPoint: ',',
      groupSeparator: '.',
      numericInput: true
    }).mask(document.getElementById("amount"))

  }


  onChallenge(e) {
    $('#rootwizard').bootstrapWizard({
      nextSelector:     '.wizard-next',
      previousSelector: '.wizard-previous',
      finishSelector:   '.wizard-finish',
      backSelector:     '.wizard-back',
      onNext: (tab, navigation, index) => {
        console.log("Tab:", tab)
        console.log("Navigation:", navigation)
        console.log("Index:", index)
        switch (index) {
          case 1:
            var valid = !!document.querySelector(".name-radio:checked");
            if(!valid)
              this.notifyOption()
            return valid
            break;
          case 2:
            var valid = !!document.querySelector(".address-radio:checked");
            if(!valid)
              this.notifyOption()
            return valid
              break;
          case 3:
            var valid = !!document.querySelector(".birthdate-radio:checked");
            if(!valid)
              this.notifyOption()
            return valid
              break;
          default:
            return false
            break;
        }
      }
    })
  }

  notifyOption(){
    this.notifyMessage("Selecione uma das opções antes de seguir.")
  }

  notifyMessage(message){
    $.notify({
      icon: "nc-icon nc-bell-55",
      message: message
    }, {
      type: 'danger',
      timer: 1000,
      placement: { from: 'top', align: 'right' }
    });
  }

  verifyChallengeData(e) {
    if(!document.querySelector(".birthdate-radio:checked")) {
      this.notifyOption()
      e.preventDefault()
    } else {
      $("#modal-verify").modal()
    }
  }

  // 
  async authSubmitForm(e){
    e.preventDefault()
    var error = this.validateErrorsForm(e.target.dataset['typeform'])
    this.error = error
    if(error){
      this.notifyMessage("Preencha os campos obrigatórios!")
    } else {
      const alertFire = await Swal2.fire({
        title: 'Confirmação',
        icon: "question",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, desejo prosseguir",
        html:
          `<p><strong>Valor Total: <span class="text-success">${document.getElementById("amount").value}</span></strong></p>
          <p><strong>Metodo de Pagamento:</strong> <span class="">${$("#payment_type option:selected").text()}</span></p>
          <p><strong>Tipo de contribuição:</strong> <span class="">${$("#qt_type option:selected").text()}</span></p>`,
        focusConfirm: true,
        showCancelButton: true
      })

      if(alertFire.isConfirmed) {
        Rails.fire(document.getElementById('client-form'), 'submit')
      } else {
        console.log("Cancelado")
      }
    }
  }

  onAuthBeforeSendForm(e){
    var error = this.validateErrorsForm('auth')
    if(error){
      e.preventDefault()
      this.notifyMessage("Preencha os campos obrigatórios!")
    } else {
      $("#modal-verify-data").modal()
    }
  }

  validateErrorsForm(typeForm){
    this.error = false
    if(typeForm === "auth"){
      if(document.querySelector(".card-input-element:checked") && document.querySelector(".card-input-element:checked").value == ""){
        document.querySelectorAll(`.card-data`).forEach((i) => {
          if (i.value == "") {
            this.addClass(i)
            this.error = true
          }
        })
        if (this.luhn_checksum(document.clientform.card_number.value.replace(/[^\d]+/g, "")) != 0) {
          this.addClass(document.clientform.card_number)
          document.clientform.card_number.nextElementSibling.innerHTML = "Cartão Inválido"
          document.clientform.card_number.focus()
          this.error = true
        }
      } else {
        if(document.getElementById("ccv2").value.replaceAll('_', '').length < 3 && this.paymentTypeTarget.value == "CreditCard")
        this.error = true
      }
    } else {

      // Limpar descritivos de erros
      document.querySelectorAll(`.field-error`).forEach((i) => {
        i.innerHTML = ""
      })

      // Remover marcadores de erros
      document.querySelectorAll(`.field_with_errors`).forEach((i) => {
        i.classList.remove("field_with_errors")
        i.classList.remove("has-danger")
      })

      // if (this.birthdayTarget.value.replace(/_/g, "").length != 10){
        
      if (!Moment(this.birthdayTarget.value, 'DD/MM/YYYY', true).isValid()){
        this.birthdayTarget.nextElementSibling.innerHTML = "Data inválida!"
        this.addClass(this.birthdayTarget)
        this.birthdayTarget.focus()
        this.error = true
      }
      
        if (!this.ValidateCPF(this.documentTarget.value)){
        this.documentTarget.nextElementSibling.innerHTML = "Documento Inválido."
        this.addClass(this.documentTarget)
        this.documentTarget.focus()
        this.error = true
      }
      
      if (document.clientform.termschk && !document.clientform.termschk.checked) {
        this.error = true
        $.notify({
          icon: "nc-icon nc-bell-55",
          message: "Você deve aceitar os termos de aceite!"
        }, {
          type: 'warning',
          timer: 2000,
          placement: {
            from: 'top',
            align: 'right'
          }
        });
      }

      switch (this.paymentTypeTarget.value) {
        case "": {
          this.addClass(this.paymentTypeTarget)
          this.paymentTypeTarget.nextElementSibling.innerHTML = "Seleciona a forma de Pagamento."
            this.error = true
            break
          }
        case "CreditCard":
        case "DebitCard": {

          this.proc3ds = this.paymentTypeTarget.value === "DebitCard"

          document.querySelectorAll(`.card-data`).forEach((i) => {
            if (i.value == "") {
              this.addClass(i)
              this.error = true
            }
          })
          if (this.luhn_checksum(document.clientform.card_number.value.replace(/[^\d]+/g, "")) != 0) {
            this.addClass(document.clientform.card_number)
            document.clientform.card_number.nextElementSibling.innerHTML = "Cartão Inválido"
            document.clientform.card_number.focus()
            this.error = true
          }
          break
        }
        case "bank_slip": {
          document.querySelectorAll(`.bank-slip-data`).forEach((i) => {
            if (i.value == "") {
              this.addClass(i)
              this.error = true
            }
          })
          this.sectionBankSlipTarget.classList.add("payment-type--current")
          break
        }
      }
    }
    return this.error
  }

  onAuthPostSuccess(e) {
    setTimeout(() => {
      $("#modal-verify-data").modal('hide')
      // document.querySelector("body").classList.remove("modal-open")
      // document.getElementById("redirect-link").setAttribute("href", e.detail[0].goto)
      // document.getElementById("redirect-msg").classList.remove('d-none')
      // document.getElementById("rootwizard").classList.add('d-none')
    }, 100)
    setTimeout(() => { Turbolinks.visit(e.detail[0].goto) }, 300 )
  }
  
  onAuthPostError(e) {
    setTimeout(() => { $("#modal-verify-data").modal('hide') }, 250 )
    console.log(e.detail)
    swal({
      title: "Ops!",
      text: e.detail[0].message,
      icon: "warning",
      dangerMode: true,
    }).then((ev) => {
      Turbolinks.visit(e.detail[0].goto)
    })
    // Turbolinks.visit(e.detail[0].goto)
  }

  onChallengePostSuccess(e) {
    setTimeout(() => {
      $("#modal-verify").modal('hide')
      document.querySelector("body").classList.remove("modal-open")
      document.getElementById("redirect-link").setAttribute("href", e.detail[0].goto)
      document.getElementById("redirect-msg").classList.remove('d-none')
      document.getElementById("rootwizard").classList.add('d-none')
    }, 250)
    // setTimeout(() => { Turbolinks.visit(e.detail[0].goto) }, 300 )
    setTimeout(() => { location.href = e.detail[0].goto }, 300 )
  }
  
  onChallengePostError(e) {
    setTimeout(() => { $("#modal-verify").modal('hide') }, 250 )
    swal({
      title: "Ops!",
      text: e.detail[0].message,
      icon: "warning",
      dangerMode: true,
    }).then((ev) => {
      Turbolinks.visit(e.detail[0].goto)
    })
    // Turbolinks.visit(e.detail[0].goto)
  }

  changeCard(event) {
    if (!event || event.currentTarget.value == ""){
      this.sectionTokenCardsTarget.classList.add("payment-type--current")
      // this.sectionNewCardFieldsTarget.classList.remove("d-none")
      this.ccv2ContentTarget.classList.add("d-none")
    } else {
      this.sectionTokenCardsTarget.classList.remove("payment-type--current")
      // this.sectionNewCardFieldsTarget.classList.add("d-none")
      this.ccv2ContentTarget.classList.remove("d-none")
    }
  }

  closeModalSubmit(e){
    console.log(e);
    $("#confirmation").modal("hide")
  }


  onIndex(e){
    sectionConfig.initTimeout("session-time")
    window.erctt = this
    new Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
    new Inputmask("99999-999").mask(document.getElementById("cep"));
    new Inputmask("999[9]").mask(document.getElementById("ccv"));
    new Inputmask("9999 9999 9999 999[9] [9][9][9]").mask(document.getElementById("card_number"));
    new Inputmask({regex: "^(1[0-2]|0[1-9]|\d)\/([0-9][0-9])$"}).mask(document.getElementById("expiration_date"));
    new Inputmask("(99) 99999-9999").mask(document.getElementById("phone"));
    new Inputmask("99/99/9999").mask(document.getElementById("birthday"));
    new Inputmask("99/99/2099").mask(document.getElementById("first_payment"));
    new Inputmask("9[9]").mask(document.getElementById("quantity_months"));
    
    // new Inputmask("99/99/9999").mask(document.getElementById("data_vencimento"));
    // new Inputmask("9").mask(document.getElementById("dv_conta"));

    new Inputmask({
      alias: 'currency',
      prefix: 'R$ ',
      radixPoint: ',',
      groupSeparator: '.',
      numericInput: true
    }).mask(document.getElementById("amount"));
    
    $("[data-provider=\"datepicker\"]").datepicker({
      startView: 3,
      language: "pt-BR"
    });
  }


  onFirstPage(e){
    new Inputmask("999.999.999-99").mask(document.getElementById("cpf"))
  }

  // submit(e) {
  //   document.getElementById("clickModal").click()
  //   bpmpi_authenticate()
  //   e.preventDefault()
  // }

  beforeSendForm(e) {
    var error = this.validateErrorsForm('full')
    
    if (error) {
      document.getElementById("closeModal").click()
      e.preventDefault();
    } else {
      $("#checking-modal").modal()

      // Limpar timeout da sessão
      sectionConfig.clearTimeout()
      
      // Condicional para ativação do 3DS
      //   - nesse caso, a submissção do formulário é canelada
      //   - e o processo de autenticação do 3DS é feita
      if(this.enabled3dsTarget && this.enabled3dsTarget.value == "true" && this.proc3ds){
        if(document.getElementById("auth_3ds_authenticate").value == ""){
          bpmpi_authenticate()
          e.preventDefault()
        }
      }
      

    }
  }

  perform3ds() {
    if(this.enabled3dsTarget && this.enabled3dsTarget.value == "true") {

    }
  }

   ValidateCPF(_cpf) {
    const cpf = _cpf ? _cpf.replace(/[^\d]+/g, '') : '';
    const falsePositive = [
      "00000000000",
      "11111111111",
      "22222222222",
      "33333333333",
      "44444444444",
      "55555555555",
      "66666666666",
      "77777777777",
      "88888888888",
      "99999999999"
    ]

    if (cpf == '' || cpf.length != 11 || falsePositive.includes(cpf)) return false

    let add = 0
    const fragment = cpf.split('').map(e => parseInt(e))
    fragment.slice(0, 9).forEach((v, i) => add += v * (10 - i))

    let rev = 11 - (add % 11) < 10 ? 11 - (add % 11) : 0
    
    if (rev != fragment[9]) return false

    add = 0
    fragment.slice(0, 10).forEach((v, i) => add += v * (11 - i))

    rev = 11 - (add % 11) < 10 ? 11 - (add % 11) : 0

    return rev == fragment[10]
  }


  addClass(el) {
    el.parentElement.classList.add("field_with_errors")
    el.parentElement.classList.add("has-danger")
  }

  onPostSuccess(event) {
    // swal({
    //   title: "Sucesso!",
    //   text: "Operação realizada com sucesso!",
    //   icon: "success",
    //   dangerMode: true,
    // }).then((e) => {
    //   Turbolinks.visit(event.detail[0].goto)
    // })
    Turbolinks.visit(event.detail[0].goto)
  }

  onPostError(event) {
    console.error("Erro", event)
    document.getElementById("closeModal").click()
    document.querySelectorAll(`[name^="auth_3ds"]`).forEach((el) => { el.value = null })
    // document.getElementById("auth_3ds_cavv").value = null
    // document.getElementById("auth_3ds_xid").value = null
    // document.getElementById("auth_3ds_eci").value = null
    // document.getElementById("auth_3ds_version").value = null
    // document.getElementById("auth_3ds_reference_id").value = null
    // document.getElementById("auth_3ds_authenticate").value = null
    // alert(event.detail[0].error)

    swal({
      title: "Ops!",
      text: event.detail[0].error,
      icon: "warning",
      dangerMode: false,
    })
  }

  luhn_checksum(code) {
    var len = code.length
    var parity = len % 2
    var sum = 0
    for (var i = len - 1; i >= 0; i--) {
      var d = parseInt(code.charAt(i))
      if (i % 2 == parity) {
        d *= 2
      }
      if (d > 9) {
        d -= 9
      }
      sum += d
    }
    return sum % 10
  }

  checkCard(e) {
    if (this.luhn_checksum(e.currentTarget.value.replace(/[^\d]+/g, "")) != 0) {
      this.addClass(e.currentTarget)
      e.currentTarget.nextElementSibling.innerHTML = "Cartão Inválido"
      this.error = true
    } else {
      e.currentTarget.nextElementSibling.innerHTML = ""
      e.currentTarget.parentElement.classList.remove("field_with_errors")
      e.currentTarget.parentElement.classList.remove("has-danger")
    }
  }


  payment_change(e) {
    for (let _section of document.getElementsByClassName("payment-type--current")) {
      _section.classList.remove("payment-type--current")
    }

    switch (e.currentTarget.value) {
      case "CreditCard":
      case "DebitCard": {

        if (e.currentTarget.value == "CreditCard") {
          document.getElementById("bpmpi_paymentmethod").value = "Credit"
          document.getElementById("brand-american_express").style.display = null
          document.getElementById("brand-diners_club").style.display = null
          document.getElementById("brand-aura").style.display = null
        } else {
          document.getElementById("bpmpi_paymentmethod").value = "Debit"
          document.getElementById("brand-american_express").style.display = 'none'
          document.getElementById("brand-diners_club").style.display = 'none'
          document.getElementById("brand-aura").style.display = 'none'
        }

        this.sectionNewCardTarget.classList.add("payment-type--current")
        document.getElementById(`labelDebitCard`).style.display = 'none'
        document.getElementById(`labelCreditCard`).style.display = 'none'
        document.getElementById(`label${e.currentTarget.value}`).style.display = 'block'
        break
      }
      case "bank_slip": {
        this.sectionBankSlipTarget.classList.add("payment-type--current")
        break
      }
      case "debit_account": {
        this.sectionDebitAccountTarget.classList.add("payment-type--current")
        break
      }
    }

  }

  authPaymentChange(e) {
    for (let _section of document.getElementsByClassName("payment-type--current")) {
      _section.classList.remove("payment-type--current")
    }

    switch (e.currentTarget.value) {
      case "CreditCard":
      case "DebitCard": {

        if (e.currentTarget.value == "CreditCard") {
          this.sectionNewCardTarget.classList.add("payment-type--current")
          document.getElementById("bpmpi_paymentmethod").value = "Credit"
          document.getElementById("brand-american_express").style.display = null
          document.getElementById("brand-diners_club").style.display = null
          document.getElementById("brand-aura").style.display = null
        } else {
          this.sectionTokenCardsTarget.classList.add("payment-type--current")
          document.getElementById("bpmpi_paymentmethod").value = "Debit"
          document.getElementById("brand-american_express").style.display = 'none'
          document.getElementById("brand-diners_club").style.display = 'none'
          document.getElementById("brand-aura").style.display = 'none'
        }

        document.getElementById(`labelDebitCard`).style.display = 'none'
        document.getElementById(`labelCreditCard`).style.display = 'none'
        document.getElementById(`label${e.currentTarget.value}`).style.display = 'block'
        break
      }
      case "bank_slip": {
        this.sectionBankSlipTarget.classList.add("payment-type--current")
        break
      }
      case "debit_account": {
        this.sectionDebitAccountTarget.classList.add("payment-type--current")
        break
      }
    }
  }

  parseMonthInput(e){
    var parsedValues = e.target.value.replaceAll('_', '').split('/')
    console.log(parsedValues)
    document.getElementById("bpmpi_cardexpirationmonth").value = parsedValues[0]
    document.getElementById("bpmpi_cardexpirationyear").value = parsedValues[1]
  }

  findCep(event) {
    if (event.currentTarget.value.length > 8) {
      var find_cep_notify = $.notify({
        icon: "nc-icon nc-bell-55",
        message: "Pesquisando o <b>CEP</b>, aguarde!"
      }, {
        type: 'info',
        timer: 4000,
        placement: {
          from: 'top',
          align: 'right'
        }
      });
      fetch(`https://viacep.com.br/ws/${event.currentTarget.value}/json/`).then((response) => {
          find_cep_notify.close();
          if (response.ok) {
            return response.json().then(function (json) {
              $.notify({
                icon: "nc-icon nc-bell-55",
                message: "Busca Finalizada!"
              }, {
                type: 'success',
                timer: 2000,
                placement: {
                  from: 'top',
                  align: 'right'
                }
              });
              document.getElementById("address").value = json.logradouro || ""
              document.getElementById("neighborhood").value = json.bairro || ""
              document.getElementById("city").value = json.localidade || ""
              document.getElementById("state").value = json.uf || ""
            });
          } else {
            $.notify({
              icon: "nc-icon nc-bell-55",
              message: "Erro ao buscar o <b>CEP</b>. Verifique a conexão!"
            }, {
              type: 'danger',
              timer: 20000,
              placement: {
                from: 'top',
                align: 'right'
              }
            });
          }
        })
        .catch((error) => {
          find_cep_notify.close();
          $.notify({
            icon: "nc-icon nc-bell-55",
            message: "Erro ao buscar o <b>CEP</b>. Verifique a conexão!"
          }, {
            type: 'danger',
            timer: 20000,
            placement: {
              from: 'top',
              align: 'right'
            }
          });
        });
    }
  }
}
